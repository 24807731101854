@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

app-root {
	min-height: 100%;
	height: fit-content;
	display: block;
}

//Fixes issue with Tailwind and Material 15. With new version in future should be able to remove
.mdc-notched-outline__notch {
	border-right: none;
}

app-manual-parts-input {
	.mat-mdc-option {
		display: block;
	}
}

//Removes arrows from number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.no-wrap-mat-form-field {
	width: 100%;
	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	.mat-mdc-text-field-wrapper {
		height: 30px;
	}

	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		padding: 0;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
	.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
		height: 30px;
	}
}

app-main-toolbar {
	.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
		right: 6px;
	}
	.mat-badge-small.mat-badge-above .mat-badge-content {
		top: 0;
	}
}
app-main-toolbar::after {
	height: 4px;
	display: block;
	top: 100%;
	right: 0px;
	left: 0px;
	background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
	content: '';
}
app-project {
	height: 100%;
	display: block;
	min-height: 100vh;
}

app-first-login-reset-password,
app-change-password-request,
app-login {
	height: calc(100vh - 50px);
	display: block;
}

.mat-mdc-dialog-container app-login {
	height: 400px;
}

.create-order-response {
	.mat-mdc-dialog-surface.mdc-dialog__surface {
		@apply overflow-hidden;
	}
	@apply w-full;
	.ok-button.mat-mdc-raised-button:not(:disabled) {
		@apply bg-primary-400 text-light;
	}
}

.Production {
	background-color: #eff1f3;
}

.Wood {
	background-color: #f4ede5;
}

.Shelving {
	background-color: #e8edfb;
}

.Production-hours {
	color: #64748b;
}

.Wood-hours {
	color: #964b00;
}

.Shelving-hours {
	color: #1d4ed8;
}

.transit-time {
	background-color: #ecf0e6;
}

.shipping-buffer {
	background-color: #fef7e7;
}
.mat-mdc-table .mdc-data-table__row {
	@apply h-10 max-h-10;
}

.mat-mdc-table .mat-mdc-header-row {
	@apply h-10 bg-gray-300;
}
.mat-mdc-table .mat-column-def {
	flex: 1;
}
.mdc-data-table__header-cell {
	@apply text-lg border-b-2 border-black;
}

.mat-form-qty-padding .mat-mdc-text-field-wrapper {
	@apply pr-1;
}
